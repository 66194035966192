/* src/components/TextDisplay.css */

.text-display-container {
  display: flex;
}



.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom-button {
  position: relative;
  background-color: rgba(12, 36, 49);
  color: #85adc7;
  border: none;
  border-radius: 5px;
  padding: 18px 40px;
  font-size: 16px;
  font-family: 'Roboto Mono', monospace;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}

.fade-container {
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Add a transition effect */
}

.fade-container.hidden {
  opacity: 0; /* Make it invisible */
  pointer-events: none; /* Optional: Prevent interactions while hidden */
}


.custom-button:hover {
  background-color: #17455F;
}

.custom-button:focus {
  outline: none;
}

.custom-button:active {
  background-color: #17455F;
  color: #00FF89;
}

.custom-button.active {
  background-color: #17455F;
  color: #00FF89;
}

.custom-button::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #17455F;
  transition: background-color 0.25s ease-in-out;
}

.custom-button.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #00FF89;
}

.paragraphs-and-lists-container {
  width: 600px;/* Adjust the width as needed */
  position: sticky;
  top: 0; /* Position the list at the top of its container */
  margin-left: 40px;
  transition: background-color 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.selected-paragraph {
  margin-left: -20px;
  align-items: left;
  justify-content: left;
  font-size: 19px;
  min-width: 10px;
  color: #00FF89;
  font-family: 'Roboto Mono', monospace;
  margin-bottom: 20px;
  left: 20px;
  transition: opacity 0.25s ease-in-out;
}

.fade-container {
  opacity: 1; /* Fully visible */
  transition: opacity 0.1s ease-in-out; /* Smooth fade transition */
}

.fade-container.hidden {
  opacity: 0; /* Fully hidden */
}


.selected-paragraph2 {
  margin-left: -20px;
  align-items: left;
  justify-content: left;
  font-size: 16px;
  min-width: 400px;
  color: #D2EEFF;
  font-family: 'Roboto Mono', monospace;
  margin-bottom: 20px;
  left: 20px;
  transition: opacity 0.25s ease-in-out;
}

.selected-list {
  list-style-type: none;
  padding-left: 20px;
  left: -15px;
}

.selected-list li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  max-width: 450px;
  color: #85adc7; /* Adjust the text color of the terms */
  font-family: 'Roboto Mono', monospace;
  font-size: 17px;
  list-style-position: outside;
}

.selected-list li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #00FF89;
}

.highlighted-word2 {
  color: #00FF89;
}