.page-container {
    padding-bottom: 100px;
}

.experience-container {
    display: flex;
    justify-content: center;
    margin-right: 50vh;
    background-color: rgba(12, 36, 49);
    margin-left: 455px;
    min-width: 600px;
    min-height: 500px; /* Adjust this value based on your tallest content */
    max-height: 800px; /* Optional: Set a maximum height if necessary */
    height: auto; /* Allows it to grow naturally within limits */
     /* Prevents content from overflowing */
    align-items: flex-start; /* Keeps content aligned to the top */
    margin-right: 60vh;
  }
  
  .etext-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    text-align: left;
    margin-top: 120px;
    margin-left: -30vh;
  }
  
  .experience-text-name {
    font-family: 'Roboto Mono', monospace;
    font-size: 23px;
    color: #00FF89;
  }
  
  .experience-text-first {
    display: inline-block;
    position: relative;
    margin-top: -0.3em;
    margin-bottom: 1em;
    font-size: 30px;
    font-family: 'Roboto', monospace;
    font-weight: 750;
    color: #D2EEFF;
    padding-right: 20px;
  }
  
  .experience-text-first::after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 100%;
    transform: translateY(50%);
    width: 300px; /* Adjust the length of the line */
    height: 0.1px; /* Adjust the height of the line */
    background-color: #1A4761; 
  }
  
  .highlighted-word {
    color: #00FF89;
  }
  
  .experience-text-main {
    text-align: left;
    width: 500px;
    font-size: 18px;
    font-family: 'Roboto Mono', monospace;
    color: #85adc7;
    margin-top: -0.25em;
    margin-bottom: 2em;
    margin-left: 300px;
    position: static;
    
  }
  
  .experience-text-bottom {
    position: absolute;
    font-size: 15px;
    font-family: 'Roboto Mono', monospace;
    color: #85adc7;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: -350px;
  } 