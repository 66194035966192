/* Styles specific to the App component */

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: rgba(12, 36, 49);
}

.app-container {
  position: relative;
  min-height: 100vh;
}

.content-container {
  padding-bottom: 100px; /* Adjust this value based on the height of the Contact component */
}

.contact-container {
  position: absolute;
  bottom: 15%;
  left: 0;
  width: 100%;
}


::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgba(12, 36, 49);
}

::-webkit-scrollbar-thumb {
  background-color: #85adc7;
  border-radius: 4px;
}

html {
  scroll-behavior: smooth;
}

.robotext {
  z-index: 1;
  font-family: 'Roboto Mono', monospace;
  color: #00FF89;
}