.page-container {
    padding-bottom: 90px;
  }
  
  .projects-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(12, 36, 49);
    margin-right: 8vh;
  }
  
  .ptext-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    text-align: left;
    margin-top: 120px;
    margin-left: -30vh;
  }
  
  .project-text-name {
    font-family: 'Roboto Mono', monospace;
    font-size: 23px;
    color: #00FF89;
  }
  
  .project-text-first {
    display: inline-block;
    position: relative;
    margin-top: -0.3em;
    margin-bottom: 1em;
    font-size: 30px;
    font-family: 'Roboto', monospace;
    font-weight: 750;
    color: #D2EEFF;
    padding-right: 20px;
  }
  
  .project-text-first::after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 100%;
    transform: translateY(50%);
    width: 300px; /* Adjust the length of the line */
    height: 0.1px; /* Adjust the height of the line */
    background-color: #1A4761; 
  }
  
  .highlighted-word {
    color: #00FF89;
  }
  
  .project-text-main {
    text-align: left;
    width: 500px;
    font-size: 18px;
    font-family: 'Roboto Mono', monospace;
    color: #85adc7;
    margin-top: -0.25em;
    margin-bottom: 2em;
    margin-left: 300px;
    position: static;
    
  }
  
  .project-text-bottom {
    position: absolute;
    font-size: 15px;
    font-family: 'Roboto Mono', monospace;
    color: #85adc7;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: -350px;
  } 

  .button-list {
    width: 100%;
    padding-bottom: 0vh;
  }
  
  /* Style for the list of buttons */
  .button-list ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-left: 35vh;
  }
  
  /* Style for each button item */
  .button-list li {
    flex: 1;
    margin-right: 15px;
  }
  
  /* Style for the button */
  .button-list button {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background-color: #133549;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    width: 300px;
    height: 300px;
  }
  
  /* Style for the button image */
  .button-list img {
    width: 50px;
    height: 50px;
    margin-right: 200px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  /* Style for the button text */
  .button-list .button-text {
    text-align: left;
    padding-left: 9px;
    width: 250px;
  }
  
  /* Style for the h4 element in the button */
  .button-list h4 {
    margin-right: 150px;
    font-family: 'Roboto Mono', monospace;
    color: #D2EEFF;
    font-size: 21px;
    margin-top: 10px;
    width: 250px;
    margin-bottom: 5px;
    transition: color 0.3s ease;
  }

  .button-list button:hover h4 {
    color: #00ff89;
  }

  /* Style for the p elements in the button */
  .button-list p {
    font-family: 'Roboto Mono', monospace;
    color: #91BCD8;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .button-text2 {
    color: #00ff89;
    font-size: 14px;
    margin: 0;
  }

  .button4 {
    margin-left: 33vh;
    outline: 0;
    grid-gap: 8px;
    align-items: center;
    background: 0 0;
    border: 1px solid #00FF89;
    font-family: 'Roboto Mono', monospace;
    color: #00FF89;
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    gap: 8px;
    justify-content: center;
    line-height: 1.5;
    overflow: hidden;
    padding: 16px 25px;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: all .14s ease-out;
    white-space: nowrap;
  }
  
  .button4:hover {
    box-shadow: 4px 4px 0 #00FF89;
    transform: translate(-4px, -4px);
  }
  
  .button4:focus-visible {
    outline-offset: 1px;
  }

  .bottom-text {
    color: #85adc7;
    font-family: 'Roboto Mono', monospace;
    font-size: 21px;
    margin-left: 33vh;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  /* Change all margins from px to vh parameters, may fix mobile weirdness */ 