.navigation {
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.horizontal-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    justify-content: flex-end;
  }

  .horizontal-list li {
    margin-left: 20px;
    counter-increment: item;
  }

  .horizontal-list li::before {
    content: counter(item) ".";
    margin-right: 5px;
    color: #00FF89;
  }

  .horizontal-list li a {
    text-decoration: none;
    color: #85ADC7;
    transition: color 0.3s ease;
    font-family: 'Roboto Mono', monospace;
    /* font-family: 'Roboto', sans-serif; Use this font for regular text on the site  */
    font-size: 18px;
  }

  .horizontal-list li a:hover {
    color: #00FF89;
  }

  .buttonicus {
    outline: 0;
    margin-left: 15px;
    margin-top: -10px;
    margin-right: 2px;
    grid-gap: 8px;
    align-items: center;
    background: 0 0;
    border: 1px solid #00FF89;
    font-family: 'Roboto Mono', monospace;
    color: #00FF89;
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    gap: 8px;
    justify-content: center;
    line-height: 1.5;
    overflow: hidden;
    padding: 8px 14px;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: all .14s ease-out;
    white-space: nowrap;
  }
  
  .buttonicus:hover {
    box-shadow: 4px 4px 0 #00FF89;
    transform: translate(-4px, -4px);
  }
  
  .buttonicus:focus-visible {
    outline-offset: 1px;
  }