.page-container {
  padding-bottom: 90px;
}

.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(12, 36, 49);
}

.atext-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  text-align: left;
  margin-top: 120px;
  margin-left: -30vh;
}

.about-text-name {
  font-family: 'Roboto Mono', monospace;
  font-size: 23px;
  color: #00FF89;
}

.about-text-first {
  display: inline-block;
  position: relative;
  margin-top: -0.3em;
  margin-bottom: 1em;
  font-size: 30px;
  font-family: 'Roboto', monospace;
  font-weight: 750;
  color: #D2EEFF;
  padding-right: 20px;
}

.about-text-first::after {
  content: '';
  position: absolute;
  bottom: 50%;
  left: 100%;
  transform: translateY(50%);
  width: 300px; /* Adjust the length of the line */
  height: 0.1px; /* Adjust the height of the line */
  background-color: #1A4761; 
}

.highlighted-word {
  color: #00FF89;
}

.about-text-main {
  text-align: left;
  width: 500px;
  font-size: 18px;
  font-family: 'Roboto Mono', monospace;
  color: #85adc7;
  margin-top: -0.25em;
  margin-bottom: 2em;
  margin-left: 300px;
  position: static;
  
}

.about-text-bottom {
  position: absolute;
  font-size: 15px;
  font-family: 'Roboto Mono', monospace;
  color: #85adc7;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: -350px;
}

.custom-list {
  list-style-type: none;
  padding-left: 20px;
}

.custom-list li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
}

.custom-list li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #00FF89;
}

.lists-container {
  display: flex;
  margin-left: 21%;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-right: 30px; /* Adjust the spacing between the lists */
}

.list li {
  margin-bottom: 10px; /* Adjust the spacing between list items */
  position: relative;
  color: #85adc7; /* Adjust the text color of the terms */
  font-family: 'Roboto Mono', monospace;
}

.list li::before {
  content: "";
  position: absolute;
  left: -20px; /* Adjust the position of the triangle */
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px; /* Adjust the size and orientation of the triangle */
  border-color: transparent transparent transparent #00FF89; /* Adjust the color of the triangle */
}

.image-container {
  position: relative;
  width: 300px; /* Adjust the width of the image container */
  height: 300px; /* Adjust the height of the image container */
  overflow: hidden;
  margin-left: 50px;
}

.image-link {
   /* Make the anchor tag a block element to take the entire space of the image */
  width: 300px; /* Adjust the width of the link container */
  height: 300px; /* Adjust the height of the link container */
  border-radius: 10px; /* Add rounded corners */
  overflow: hidden; /* Hide the overflowing parts of the image */
}

/* Add styles for the normal image */
.image-normal {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px; /* Add rounded corners */
  transition: opacity 0.3s ease-in-out; /* Add the transition effect */
}

/* Add styles for the hovered image */
.image-hovered {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1; /* Start with 0 opacity (invisible) */
  border-radius: 10px; /* Add rounded corners */
  
  cursor: default;
}



/* CSS */
.button-56 {
  align-items: center;
  background-color: #85adc7;
  border: 3px solid #00FF89;
  border-radius: 10px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 300px;
  width: 300px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  overflow: hidden; /* Hide the overflowing parts of the image */
  border-radius: 10px; /* Add rounded corners */
  transition: background-color 0.3s ease-in-out; /* Add transition effect for background color */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.button-56 .image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px; /* Add rounded corners */
}

.button-56 .image-normal,
.button-56 .image-hovered {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.button-56 .image-hovered {
  opacity: 0;
}

.button-56:hover .image-hovered {
  opacity: 1;
}

.button-text {
  position: relative; /* Ensure z-index works correctly */
  z-index: 1; /* Place the text above the image */
}