.home-container {
    background-color: rgba(12, 36, 49);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .text-container {
    text-align: left;
  }

  .home-text-name { 
    margin-left: 10%;
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    color: #00FF89;
    margin-bottom: -2.3em;
  }
  
  .home-text-first {   
    margin-left: 10%;
    font-size: 80px;
    line-height: 1.1;
    font-family: 'Roboto', monospace;
    font-weight: 700;
    color: #D2EEFF;
    margin-bottom: 0.1em;
  }
  
  .home-text-second {   
    margin-left: 10%;
    font-size: 70px;
    line-height: 1.1;
    font-family: 'Roboto', monospace;
    color: #91BCD8;
    margin-top: 0em;
    margin-bottom: 1em;
  }
  
  .home-text-main {
    margin: 0 auto;
    max-width: 1250px;
    margin-left: 10%;
    margin-right: 35%;
    font-size: 23px;
    font-family: 'Roboto Mono', monospace;
    color: #85adc7;
    margin-top: -2em;
    margin-bottom: 2em;
    
  }
  
  .highlighted-word {
    color: #00FF89;
  }

  .button-link {
    display: inline-block;
    text-decoration: none;
  }

  .button2 {
    outline: 0;
    margin-left: 71%;
    grid-gap: 8px;
    align-items: center;
    background: 0 0;
    border: 1px solid #00FF89;
    font-family: 'Roboto Mono', monospace;
    color: #00FF89;
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    gap: 8px;
    justify-content: center;
    line-height: 1.5;
    overflow: hidden;
    padding: 16px 25px;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: all .14s ease-out;
    white-space: nowrap;
  }
  
  .button2:hover {
    box-shadow: 4px 4px 0 #00FF89;
    transform: translate(-4px, -4px);
  }
  
  .button2:focus-visible {
    outline-offset: 1px;
  }

  .highlighted-word2 {
    color: #00FF89;
  }

  /* serif, sans serif, cursive, fantasy, and monospace */