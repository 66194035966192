footer {
  background-color: rgba(12, 36, 49);
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
}
  
.social-list li {
  margin-bottom: 25px;
}

.social-list li:last-child {
  margin-bottom: 120px;
}
  
.social-line {
  content: "";
  position: fixed;
  bottom: 0;
  margin-left: 60px;
  transform: translateX(-50%);
  width: 2px; 
  height: 100px; 
  background-color: #85ADC7; 
  z-index: 1; 
}
  
.container > .social-list {
  transform: translateY(-100%); /* Move the list items above the line */
}

.social-list li img{
  transition: transform 0.2s ease-in-out;
  will-change: transform, margin-top;
}
  
.social-list li:nth-child(1) img:hover {
  transform: translateY(-5px);
  content: url("../../public/githubgreen.svg");
}
  
.social-list li:nth-child(2) img:hover {
  transform: translateY(-5px);
  content: url("../../public/linkedingreen.svg");
}
  
.social-list li:nth-child(3) img:hover {
  transform: translateY(-5px);
  content: url("../../public/quantgreen.svg");
} 

.vertical-text-container {
  display: flex;
  justify-content: flex-end;
  height: 100vh;
}

.vertical-text {
  white-space: nowrap;
  font-family: 'Roboto Mono', monospace;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin-right: 50px;
  position: fixed;
  bottom: 115px; /* Adjust this value to position the link above the line */
}

.vertical-text a {
  color: #85ADC7;
  text-decoration: none;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  display: inline-block;
}

.vertical-text a:hover {
  color: #00FF89;
  transform: translateY(-5px);
}

.vertical-line-container {
  position: fixed;
  bottom: 0;
  margin-right: 60px; 
  width: 2px;
  height: 100px; 
  background-color: #85ADC7;
  z-index: 1;
}