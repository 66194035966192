
.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(12, 36, 49);
}


.ctext-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}

.contact-text-name {
  font-family: 'Roboto Mono', monospace;
  font-size: 23px;
  color: #00FF89;
}

.contact-text-first {
  margin-top: -0.3em;
  margin-bottom: 1em;
  font-size: 60px;
  font-family: 'Roboto', monospace;
  font-weight: 750;
  color: #D2EEFF;
}

.highlighted-word {
  color: #00FF89;
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
}

.contact-text-main {
  max-width: 700px;
  font-size: 23px;
  font-family: 'Roboto Mono', monospace;
  color: #85adc7;
  margin-top: -2em;
  margin-bottom: 2em;
}

.contact-text-bottom {
  position: absolute;
  font-size: 15px;
  font-family: 'Roboto Mono', monospace;
  color: #85adc7;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-bottom: -30vh;
}

.button3 {
  margin-left: 10px;
  outline: 0;
  grid-gap: 8px;
  align-items: center;
  background: 0 0;
  border: 1px solid #00FF89;
  font-family: 'Roboto Mono', monospace;
  color: #00FF89;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 16px;
  gap: 8px;
  justify-content: center;
  line-height: 1.5;
  overflow: hidden;
  padding: 16px 25px;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all .14s ease-out;
  white-space: nowrap;
}

.button3:hover {
  box-shadow: 4px 4px 0 #00FF89;
  transform: translate(-4px, -4px);
}

.button3:focus-visible {
  outline-offset: 1px;
}
